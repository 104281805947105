import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuidv4 } from 'uuid'
import { getRelativeUrl } from 'src/utils/urlUtils'

import {
  StyledGridContainer,
  StyledPaper,
  Title,
  Seperator,
  CompanyWrapper,
  CompanyIcon,
  Company,
  Wysiwyg,
  MyStyledLink,
} from './style'

const ContactCard = ({ title, company, address, links }) => {
  const mappedLinks = links.map(({ link, hashighlight }) => {
    return (
      <MyStyledLink
        key={uuidv4()}
        href={getRelativeUrl(link.url)}
        hashighlight={hashighlight}
      >
        {link.title}
      </MyStyledLink>
    )
  })

  return (
    <Grid item xs={12} lg={6}>
      <StyledPaper elevation={4}>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <Title>{title}</Title>
            <Seperator />
          </Grid>
          <Grid item xs={12} xl={6}>
            <CompanyWrapper>
              <CompanyIcon />
              <Company>{company}</Company>
            </CompanyWrapper>
            <Wysiwyg>{ReactHtmlParser(address)}</Wysiwyg>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Box mt={{ xs: 4, xl: 0 }}>{mappedLinks}</Box>
          </Grid>
        </StyledGridContainer>
      </StyledPaper>
    </Grid>
  )
}

ContactCard.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
}

export default ContactCard
