import React from 'react'
import styled from 'styled-components'
import {
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
} from '@material-ui/core'
import { FontStyle, TextBoldStyle } from 'src/styles/typography'
import { breakpoint } from 'src/styles/mixins'

export const Form = styled.form`
  position: relative;
  padding: 16px;
  background-color: ${props => props.theme.color.lightblue};

  ${breakpoint.lg`
    padding: 40px;
  `}
`

export const StyledCheckbox = styled(Checkbox)`
  margin: -8px 0 0 -8px;
`

// Do not pass the hasError prop to the dom element
export const StyledFormControlLabel = styled(({ hasError, ...rest }) => (
  <FormControlLabel {...rest} /> // eslint-disable-line
))`
  margin: 0;
  align-items: start;
  ${props => props.hasError && `color:${props.theme.color.error};`}
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 55px;
`

export const StyledTextField = styled(TextField)`
  label {
    ${FontStyle}
  }

  input,
  textarea {
    background-color: ${props => props.theme.color.white};
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }

  .MuiFilledInput-multiline {
    background-color: ${props => props.theme.color.white};
  }

  .MuiFilledInput-underline:before {
    border-color: ${props => props.theme.color.lightgrey};
  }
`

export const SuccessMessage = styled.p`
  ${TextBoldStyle}
  color: ${props => props.theme.color.green};
`

export const ErrorMessage = styled.p`
  ${TextBoldStyle}
  color: ${props => props.theme.color.error};
`
