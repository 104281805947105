import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import SectionPattern from 'src/components/SectionPattern'
import Heading2Line from 'src/components/Heading2Line'
import ContactCard from 'src/components/ContactCard'
import { getThemeVar } from 'src/styles/mixins'
import { v4 as uuidv4 } from 'uuid'

import { StyledGrid } from './style'

const ContactAtf = ({ preHeading, heading, contacts }) => {
  const mappedCards = contacts.map(contact => {
    return (
      <ContactCard
        key={uuidv4()}
        title={contact.title}
        company={contact.company}
        address={contact.addressWysiwyg}
        links={contact.repeater}
      />
    )
  })

  return (
    <>
      <Section
        background={getThemeVar('color.lightblue')}
        hasPadding
        paddingValues={{ xs: '32px 0 120px 0', lg: '72px 0 180px 0' }}
      >
        <Heading2Line preHeading={preHeading} heading={heading} />
      </Section>
      <Section>
        <SectionPattern positionX="right" />
        <StyledGrid container spacing={4}>
          {mappedCards}
        </StyledGrid>
      </Section>
    </>
  )
}

ContactAtf.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      addressWysiwyg: PropTypes.string,
      company: PropTypes.string,
      fieldGroupName: PropTypes.string,
      title: PropTypes.string,
      repeater: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export default ContactAtf
