import React , { useRef } from 'react'
import PropTypes from 'prop-types'
import Pattern from 'src/components/Pattern'
import { Grid, FormGroup } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import emailjs from 'emailjs-com'
import { useStaticQuery, graphql } from 'gatsby'
import ReCAPTCHA from "react-google-recaptcha";


import {
  Form,
  StyledTextField,
  StyledCheckbox,
  StyledButton,
  StyledFormControlLabel,
  SuccessMessage,
  ErrorMessage,
} from './style'

const ContactForm = ({ form, pageLocale }) => {

  const [checked, setChecked] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isSuccessfull, setIsSuccessfull] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [token, setToken] = React.useState(''); //eslint-disable-line

  const recaptchaRef = useRef();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const data = useStaticQuery(graphql`
    query FormMessages {
      allWp {
        edges {
          node {
            siteGeneralSettings {
              acfOptions {
                contactMessagesDe {
                  success
                  error
                }
                contactMessagesEn {
                  success
                  error
                }
                contactMessagesJa {
                  success
                  error
                }
              }
            }
          }
        }
      }
    }
  `)

  let success;
  let error;

  switch (pageLocale) {
    case 'en_US':
      success = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesEn.success
      error = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesEn.error
      break
    case 'ja':
      success = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesJa.success
      error = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesJa.error
      break
    default:
      success = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesDe.success
      error = data.allWp.edges[0].node.siteGeneralSettings.acfOptions.contactMessagesDe.error
      break
  }

  const mailSchema = yup.object().shape({
    title: yup.string(),
    company: yup.string(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string(),
    message: yup.string().required(),
    gdpr: yup.boolean().required().oneOf([true]),
  });

  const { register, handleSubmit, errors, reset, control} = useForm({
    validationSchema: mailSchema,
    resolver: yupResolver(mailSchema),
     defaultValues: {
      title: "",
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      gdpr: false,
     },
  });

  const resetForm = () => {
    reset({
      title: "",
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      gdpr: false,
    });

    recaptchaRef.current.reset();
  }
  const onCaptchaChange = () => {
    // send the message when the captcha value is set
    // credentials & keys are from multi24 
    if(isSending) {
      emailjs.send(
        process.env.GATSBY_EMAILJS_SERVICE_KEY,
        process.env.GATSBY_EMAILJS_TEMPLATE_KEY,
        formValues,
        process.env.GATSBY_EMAILJS_USER_ID
      ).then(response => { //eslint-disable-line
        setIsError(false);
        setIsSuccessfull(true);
        resetForm();
      }).catch(err => { //eslint-disable-line
        setIsSuccessfull(false);
        setIsError(true);
      }).finally(() => {
        setIsSending(false);
        setChecked(false);
      });
    }
  }

  const onSubmit = (values) => {
    setIsSending(true);
    setFormValues(values);
    // execute invisible captcha
    recaptchaRef.current.execute();
  }

  return (
    <Pattern positionX="left">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              id="title"
              name="title"
              label={form.titleLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.title}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              name="company"
              label={form.companyLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.company}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              id="first-name"
              name="first_name"
              label={form.firstNameLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.first_name}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              id="last-name"
              name="last_name"
              label={form.lastNameLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.last_name}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              id="e-mail"
              name="email"
              label={form.eMailLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.email}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledTextField
              id="phone"
              name="phone"
              label={form.phoneLabel}
              variant="filled"
              fullWidth
              inputRef={register}
              error={!!errors.phone}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <StyledTextField
              id="message"
              name="message"
              label={form.messageLabel}
              variant="filled"
              fullWidth
              multiline
              rows={8}
              inputRef={register}
              error={!!errors.message}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormGroup aria-label="position" row>
              <StyledFormControlLabel
                value="end"
                control={<StyledCheckbox color="primary" checked={checked} value={checked} onChange={handleChange} name="gdpr" />}
                label={form.gdprAcceptanceLabel}
                labelPlacement="end"
                inputRef={register}
                hasError={!!errors.gdpr}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={12}>
            <StyledButton variant="contained" color="primary" disableElevation type="submit" disabled={isSending}>
              {form.buttonLabel}
            </StyledButton>
          </Grid>
          <Grid item xs={12} lg={12}>
            {
              isSuccessfull &&
              (
                <SuccessMessage>{success}</SuccessMessage>
              )
            }
            {
              isError &&
              (
                <ErrorMessage>{error}</ErrorMessage>
              )
            }
          </Grid>
        </Grid>

        {/* CHECK WITH EMAILJS FIRST, Captcha Key is from Florian Reitmeir/multi24 */}
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          onChange={onCaptchaChange}
        />
      </Form>
    </Pattern>
  )
}

ContactForm.propTypes = {
  form: PropTypes.shape({
    titleLabel: PropTypes.string,
    companyLabel: PropTypes.string,
    firstNameLabel: PropTypes.string,
    lastNameLabel: PropTypes.string,
    eMailLabel: PropTypes.string,
    phoneLabel: PropTypes.string,
    messageLabel: PropTypes.string,
    gdprAcceptanceLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
  }).isRequired,
  pageLocale: PropTypes.string.isRequired,
}

export default ContactForm
