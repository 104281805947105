import styled from 'styled-components'
import { Map } from 'google-maps-react'
import { breakpoint } from 'src/styles/mixins'

// eslint-disable-next-line
export const StyledMap = styled(Map)`
  position: relative !important;
  height: 320px !important;
  width: 100% !important;

  ${breakpoint.lg`
    height: 620px !important;
  `}
` 
