import React from 'react'
import PropTypes from 'prop-types'
import ContactAtf from 'src/sections/contact/ContactAtf'
import ContactFormSection from 'src/sections/contact/ContactForm'

export const ContactTemplate = ({ acf, pageLocale }) => {
  const contactData = acf.contactContactData
  const form = acf.contactForm
  const map = acf.contactMap

  return (
    <>
      <ContactAtf
        preHeading={contactData.preHeading}
        heading={contactData.heading}
        contacts={contactData.repeater}
      />

      <ContactFormSection
        preHeading={form.preHeading}
        heading={form.heading}
        textWysiwyg={form.textWysiwyg}
        form={form.form}
        map={map}
        pageLocale={pageLocale}
      />
    </>
  )
}

ContactTemplate.propTypes = {
  acf: PropTypes.shape({
    contactContactData: PropTypes.shape({
      heading: PropTypes.string,
      preHeading: PropTypes.string,
      repeater: PropTypes.arrayOf(
        PropTypes.shape({
          repeater: PropTypes.arrayOf(
            PropTypes.shape({
              link: PropTypes.PropTypes.shape({
                title: PropTypes.string,
                url: PropTypes.string,
              }),
              hasHighlight: PropTypes.bool,
            })
          ).isRequired,
        })
      ).isRequired,
    }).isRequired,

    contactForm: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      form: PropTypes.shape({
        titleLabel: PropTypes.string,
      }).isRequired,
    }).isRequired,

    contactMap: PropTypes.shape({
      mapCenter: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      repeater: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        })
      ),
      zoom: PropTypes.number,
    }).isRequired,
  }).isRequired,
  pageLocale: PropTypes.string.isRequired,
}

export default ContactTemplate
