import React from 'react'
import PropTypes from 'prop-types'
import { GoogleApiWrapper, Marker } from 'google-maps-react'
import MarkerIcon from 'src/img/location-pin-green.png'
import { v4 as uuidv4 } from 'uuid'
import mapStyle from './mapStyle.json'
import { StyledMap } from './style'

const GoogleMapView = ({ google, zoom, initialCenter, markers }) => {
  const mappedMarkers = markers.map(marker => {
    return <Marker key={uuidv4()} icon={MarkerIcon} position={marker} />
  })

  return (
    <StyledMap
      google={google}
      zoom={zoom}
      styles={mapStyle}
      initialCenter={initialCenter}
      disableDefaultUI
      panControl
      zoomControl
    >
      {mappedMarkers}
    </StyledMap>
  )
}

GoogleMapView.propTypes = {
  google: PropTypes.shape({
    maps: PropTypes.object, // eslint-disable-line
  }).isRequired,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ),
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
}

GoogleMapView.defaultProps = {
  markers: [
    {
      lat: 47.25917,
      lng: 11.37383,
    },
  ],
  initialCenter: {
    lat: 47.25917,
    lng: 11.37383,
  },
  zoom: 13,
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBFBN7e4aF9MQhM4XViL8Lz7Pqr5D0rOgU",
})(GoogleMapView)
