import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'src/styles/mixins'
import { Grid, Paper } from '@material-ui/core'
import { FontStyle, TextBold, TextDefaultStyle } from 'src/styles/typography'
import Icon from 'src/img/location-pin-green.inline.svg'

export const StyledGridContainer = styled(Grid)`
  padding: 16px;

  ${breakpoint.lg`
    padding: 40px;
  `}
`

export const StyledPaper = styled(Paper)`
  height: 100%;
  border-radius: 0;
`

export const Title = styled.h2`
  ${FontStyle}
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 0;
`

export const CompanyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`

export const CompanyIcon = styled(Icon)`
  width: 10px;
  height: 16px;
  margin-right: 8px;
  margin-top: 2px;
`

export const Company = styled(TextBold)`
  margin: 0;
`

// Do not pass color to the dom element
export const MyStyledLink = styled(({ hashighlight, ...rest }) => (
  <a {...rest} /> // eslint-disable-line
))`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.font.primary};
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
  color: ${props => {
    if (props.hashighlight) {
      return props.theme.color.green
    }
  }};
`

export const Wysiwyg = styled.div`
  p {
    ${TextDefaultStyle}
    line-height: 1.4;
    margin: 0;
    color: ${props => props.theme.color.mediumgrey};
  }
`

export const Seperator = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${props => props.theme.color.green};
  margin: 16px 0;
`
