import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
import ContactForm from 'src/components/ContactForm'
import Pattern from 'src/components/Pattern'
import GoogleMap from 'src/components/GoogleMap'
import { Grid, Box } from '@material-ui/core'

const ContactFormSection = ({
  preHeading,
  heading,
  textWysiwyg,
  form,
  map,
  pageLocale,
}) => {
  return (
    <Section hasPaddingLarge paddingValuesLarge={{ xs: '48px 0  96px 0', lg: '100px 0 148px 0' }}>
      <Heading2Line preHeading={preHeading} heading={heading} />
      <Grid container>
        <Grid item xs={12} lg={9}>
          <Wysiwyg>{textWysiwyg}</Wysiwyg>
        </Grid>
      </Grid>

      <Box mt={{ xs: 4, lg: 8 }}>
        <Grid container spacing={8}>
          <Grid item xs={12} lg={6}>
            <ContactForm form={form} pageLocale={pageLocale} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mt={{ xs: 0, lg: 8 }}>
              <Pattern position="right">
                <GoogleMap
                  markers={map.repeater}
                  initialCenter={map.mapCenter}
                  zoom={map.zoom}
                />
              </Pattern>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}

ContactFormSection.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  textWysiwyg: PropTypes.string.isRequired,
  form: PropTypes.shape({
    titleLabel: PropTypes.string,
  }).isRequired,
  map: PropTypes.shape({
    zoom: PropTypes.number,
    mapCenter: PropTypes.object, // eslint-disable-line
    repeater: PropTypes.array, // eslint-disable-line
  }).isRequired,
  pageLocale: PropTypes.string.isRequired,
}

export default ContactFormSection
