import styled from 'styled-components'
import { Grid } from '@material-ui/core/'
import Section from 'src/components/Section'
import { breakpoint } from 'src/styles/mixins'

export const StyledSection = styled(Section)`
  && {
    padding-bottom: 140px;
  }
`

export const StyledGrid = styled(Grid)`
  margin-top: -120px;

  ${breakpoint.lg`
    margin-top: -160px;
  `}
`
